@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0px;
    text-align: center;
    font-family: "Open Sans", serif;
}

* {
    font-family: "Open Sans", serif !important;
}

path {
    color: inherit;
}